export const vacancyData = {
  "results": [
    {
      "Job_ID": "0",
      "Date": "29/05/2022",
      "Job_Title": "LIVE IN CARERS",
      "Job_Description": "Lyora Healthcare services Ltd have some fantastic Live-care opportunities for male and female carers with a driving licence to join a team of carers.\n\nMain Responsibilities:\n\nPersonal Care \\\nDomestic Support \\\nDriving the service user  \\\nEmotional Support \\\nRehabilitation  \\\nMonitoring service users’ overall well-being. \n\n",
      "Job_Intro": "Lyora Healthcare services Ltd have some fantastic Live-care opportunities for male and female carers with a driving licence to join a team of carers ..."
    },
    {
      "Job_ID": "1",
      "Date": "29/05/2022",
      "Job_Title": "HEALTH CARE ASSISTANT",
      "Job_Description": "We are looking for a healthcare assistant who will assist healthcare professionals, under the supervision of qualified nursing staff, to carry out a spectrum of duties. \n\nTheir tasks include assisting patients with personal care and conducting clinical duties, in addition to carrying out, monitoring, and recording the results of medical tests, which the healthcare professional will analyze.\n\nHealthcare Assistant Responsibilities: \\\nProviding personal care \\\nEnsuring that supplies and medical equipment are replenished. \\\nImproving the mobility of patients. \\\nSetting up equipment required by healthcare professionals. \\\nTaking the temperature, blood pressure, and blood glucose levels of patients, and recording the results. \\\nRecording the pulse rate of patients. \\\nMeasuring patients' weight. \\\nPreparing patients for therapy. \\\nMaking up beds, giving out bedpans, and collecting bedpans. ",
      "Job_Intro": "We are looking for a healthcare assistant who will assist healthcare professionals, under the supervision of qualified nursing staff, to carry out a spectrum of duties."
    }
  ]
}