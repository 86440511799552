import React from 'react';

export const data = {
  whoWeAre: `Lyora Healthcare Services Ltd, established as a domiciliary care provider, aims to offer top-tier nursing
    and personal care to individuals in various care facilities across England, whether on a long-term or temporary
    arrangement. Committed to delivering adaptable, prompt, and unbiased care, Lyora Healthcare Services Ltd 
    upholds the principles of independence, privacy, dignity, and the right of service users to make choices and 
    assume risks. \n \n \n Central to our mission is the collaborative effort with service users, their families, 
    and other entities to ensure exceptional care that fosters autonomy, guided by common ethical standards.`
    .split('\n \n \n')
    .map((paragraph, index) => (
      <div key={index}>
        {paragraph}
      </div>
    ))
};