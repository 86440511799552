import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@material-ui/core/Grid";
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import PhoneCallbackRoundedIcon from '@mui/icons-material/PhoneCallbackRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import Button from '@material-ui/core/Button';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import logo from '../assets/static/images/lyora-logo-v3.png';
import SimpleMenu from "./SimpleMenu";

import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  customizeToolbar: {
    minHeight: 36,
    backgroundColor: 'whitesmoke',
    borderBottom: '1px solid #fff2f2',
    paddingTop: '4px',
    color: 'black',
  },
  logoToolbar: {
    margin: '20px 0px'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  rightContactBar: {
    textAlign: 'right',
    ['@media (max-width:645px)']: {
      textAlign: 'left',
      display: 'None'
    }
  },
  leftContactBar: {
    textAlign: 'left',
    ['@media (max-width:645px)']: {
      textAlign: 'center',
      fontSize: '10px'
    }
  },
  title: {
    // display: 'none',
    fontWeight: 900,
    fontSize: '26px',
    ['@media (max-width:780px)']: {
      fontSize: '18px'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  menuButtons: {
    padding: '0px 40px',
    textTransform: 'none',
    fontSize: '1.1rem'
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


  const handleMobileMenuClose = (event) => {
    const page = event.currentTarget.textContent
    setMobileMoreAnchorEl(null);
    handleNavigate(page)

  };

  let history = useHistory();

  function handleNavigate(page) {
    if (page.toLowerCase() === 'about us') {
      history.push('/about-us');
    }
    if (page.toLowerCase() === 'services') {
      history.push('/services');
    }
    if (page.toLowerCase() === 'contact') {
      history.push('/contact');
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMobileMenuClose}>
        SERVICES
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        ABOUT US
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
       CONTACT
      </MenuItem>

    </Menu>
  );


  return (
    <div className={classes.grow}>
      <ElevationScroll {...props}>
      <AppBar position="fixed" style={{ background: 'white', color: '#133459' }}>
        <Toolbar className={classes.customizeToolbar}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} className={classes.leftContactBar}>
              <div>
              </div>
              <div>
                <PhoneCallbackRoundedIcon className="material-icons"/>
                <span style={{fontWeight: 'bold', paddingRight: '8px'}}> 07476 681686</span> |
                <span style={{paddingLeft: '8px'}}>
                  <EmailRoundedIcon className="material-icons"/> contact@lyorahealthcareservices.co.uk
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.rightContactBar}>

            </Grid>
          </Grid>

        </Toolbar>
        <Toolbar className={classes.logoToolbar}>
          <div onClick={() => history.push('/')}>
            <img
              style={{cursor: 'pointer', maxHeight: '90px'}}
              src={logo}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <SimpleMenu/>
            <Button className={classes.menuButtons} color="inherit" onClick={handleMobileMenuClose}>About us</Button>
            <Button className={classes.menuButtons} color="inherit" onClick={handleMobileMenuClose}>Contact</Button>

          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      </ElevationScroll>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
