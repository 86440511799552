import React, {useEffect, useState} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import wallPaper from "../../assets/static/images/care-at-excel2.jpg";
import excelCarer from "../../assets/static/images/excel-carer-with-client.jpg"
import FormDialog from "./../FormDialogueComponent";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Divider from '@mui/material/Divider';
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(14, 0, 6),
    backgroundImage: `url(${wallPaper})`,
    backgroundColor: 'rgba(0, 0, 0, 0.13)',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover'
  },
  personalCareContent: {
    backgroundImage: `url(${excelCarer})`,
    backgroundSize: 'cover',
    minHeight: '250px',
    borderRadius: '4px'
  },
  sections: {
  },
  sectionsOne: {
    backgroundColor: 'white'
  },
  sectionsTwo: {
    backgroundColor: '#f9f9fa'
  },
  sectionTitles: {
    paddingBottom: '30px',
    fontWeight: 'bolder',
    paddingTop: '64px',
  },
  question: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    paddingBottom: "8px"
  },
  answer: {
    fontStyle: "italic",
    paddingTop: "4px",
    paddingBottom: "48px"
  },
  coreValues: {
    fontWeight: 'bold'
  },
  itemList: {
    fontSize: '1rem'
  }
}));


export default function HomeCarePage(props) {

  const classes = useStyles();
  const [openForm, setOpenForm] = useState(false);
  const [postcode, setPostcode] = React.useState('');

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="Domiciliary Care and Home Care" />
        <title>Lyora Healthcare Services - Social and Domiciliary care</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
            <BreadcrumbItem active>Home care</BreadcrumbItem>
          </Breadcrumb>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              style={{fontWeight: 'bold', color: 'white'}}
              gutterBottom>
              <span className="page-header">Home care</span>
            </Typography>
          </Container>
        </div>
      </main>
      <div>
        <div className={classes.sectionsOne}>
          <Container maxWidth="md" style={{paddingBottom: '40px'}}>
            <FormDialog
              openForm={openForm}
              handleFormClose={handleCloseForm}
              postcode={postcode}
            />
            <Typography
              component="h1"
              variant="h5"
              align="center"
              color="textPrimary"
              className={classes.sectionTitles}
              gutterBottom>
              Need help with Home Care?
            </Typography>
            <Typography>
              You can discover top-notch Home Care Specialists right here at Lyora Healthcare Services.
              Begin your search and request free quotes today!
            </Typography>
            <br/>
            <Typography>
              If you're new to the process of finding a Home Care Specialist and need guidance,
              let us know about your needs and preferences, and we'll provide you with
              experienced Home Care Specialists for your consideration.
              You're under no obligation to commit, so you have the freedom to read our reviews,
              and request additional information before making your decision.
            </Typography>
            <br/>
            <Button
              variant="contained"
              color="secondary"
              className="mui-buttons"
              onClick={() => setOpenForm(true)}
              disableElevation>
              Request a Free Consultation </Button>
          </Container>
        </div>
        <div className={classes.sectionsTwo}>
          <Container maxWidth="md" style={{paddingTop: '48px', paddingBottom: '48px'}}>
            <Grid container spacing={3}>
              <Grid className={classes.personalCareContent} item xs={12} sm={6}>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography style={{fontSize: '1.1rem', paddingBottom: '8px'}} className={classes.coreValues}>
                  Home care services we offer:
                </Typography>
                <ListItem>
                  <span className={classes.itemList}>Personal care: Washing, bathing, oral hygiene, make-up </span>
                </ListItem>
                <Divider/>
                <ListItem>
                  <span className={classes.itemList}>Cooking: Support with preparing and serving meals</span>
                </ListItem>
                <Divider/>
                <ListItem>
                  <span className={classes.itemList}>Domestic: Laundry, cleaning </span>
                </ListItem>
                <Divider/>
                <ListItem>
                  <span className={classes.itemList}>Continence: Support with dressing / undressing </span>
                </ListItem>
                <Divider/>
                <ListItem>
                  <span className={classes.itemList}>Medication: Ear / Eye care, prescribed medication and creams</span>
                </ListItem>
              </Grid>
            </Grid>

          </Container>
        </div>
        <div className={classes.sectionsOne}>
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h5"
              align="center"
              color="textPrimary"
              className={classes.sectionTitles}
              gutterBottom>
              Frequently Ask Questions
            </Typography>
            <Typography>
              <div className={classes.question}>
                How does domiciliary home care compare to other care options, and is it the right choice
                for my loved one?
              </div>
              <div className={classes.answer}>
                Domiciliary / Home care offers several advantages compared to other care options such as assisted living
                or nursing homes. It allows your loved one to receive personalized care in the comfort of
                their own home, maintaining their independence and familiar surroundings.
                This option is particularly beneficial if your loved one values autonomy and a sense of familiarity.
                <br/>
                <br/>
                Additionally, domiciliary home care is often more cost-effective than residential care facilities.
                It can be tailored to your loved one's specific needs, ensuring they receive the level of care
                necessary without paying for unnecessary services.
                <br/>
                <br/>
                Whether domiciliary home care is the right choice depends on your loved one's individual
                circumstances, preferences, and care needs. We recommend scheduling an assessment to
                determine the most suitable care plan and discuss the best options for your loved one's well-being
                and quality of life.
              </div>
              <div className={classes.question}>
                What types of services do you provide in domiciliary home care?
              </div>
              <div className={classes.answer}>
                Personal Care: Our carers are trained to assist with various personal care tasks,
                including washing, bathing, oral hygiene, and even help with makeup application when needed.
                <br/>
                <br/>
                Cooking: We provide support with meal preparation and serving, ensuring that our clients receive
                nutritious and delicious meals that meet their dietary preferences and requirements.
                <br/>
                <br/>
                Domestic Services: Our caregivers assist with household chores such as laundry and cleaning,
                helping to maintain a clean and comfortable living environment for our clients.
                <br/>
                <br/>
                Continence Care: We offer support with dressing and undressing, respecting our clients'
                dignity and privacy while ensuring their comfort and well-being.
                <br/>
                <br/>
                Medication Management: Our services extend to providing ear and eye care, as well as
                assisting with prescribed medications and creams as directed by medical professionals.
                Your loved one's health and medication regimen are in safe hands with us.
              </div>
              <div className={classes.question}>
                Can you help assess my loved one's specific care needs and create a personalised care plan?
              </div>
              <div className={classes.answer}>
                Certainly, we are dedicated to assessing your loved one's unique care needs and developing a
                personalized care plan tailored to their requirements.
                <br/>
                <br/>
                Our experienced care professionals will conduct a thorough assessment, taking into account their
                medical history, preferences, and daily routines. This assessment forms the basis for a
                customized care plan that addresses their specific needs and ensures they receive the highest
                quality of care while maintaining their comfort and independence at home.
                <br/>
                <br/>
                Your loved one's well-being and individuality are at the forefront of our care planning process.
              </div>
              <div className={classes.question}>
                Are your carers trained and experienced?
              </div>
              <div className={classes.answer}>
                Yes, all our caregivers are highly trained and experienced professionals. We understand the
                importance of having a skilled and compassionate team to provide the best care for your loved ones.
                <br/>
                <br/>
                Additionally, they receive ongoing education and mentorship to stay updated on the latest care
                practices and provide the highest quality of care. <br/>
                Your loved one's well-being and safety are our top priorities, and our caregivers are committed
                to delivering excellence in care.
              </div>
              <div className={classes.question}>
                Is Lyora Healthcare services covered by insurance, and what are the payment options available?
              </div>
              <div className={classes.answer}>
                Yes, Lyora Healthcare Service is fully insured,
                <br/>
                In terms of payment options, we offer flexibility to accommodate different financial situations.
                While insurance coverage may be an option for some clients, we also accept private payments.
                Our team can work with you to explore the most suitable payment arrangement based on your
                needs and circumstances
              </div>
              <div className={classes.question}>
                Do you offer flexible scheduling options for care services, including weekends and holidays?
              </div>
              <div className={classes.answer}>
                Absolutely, we offer flexible scheduling options for care services, including weekends and holidays.
                Our commitment to providing care extends 24/7,
                ensuring that your loved one receives the support they need, whenever they need it.
              </div>
              <div className={classes.question}>
                How can I get started with domiciliary home care services for my loved one,
                and what's the first step in the process?
              </div>
              <div className={classes.answer}>
                To get started with domiciliary home care services for your loved one,
                the first step is to reach out to us for a free consultation and personalized care assessment.
                Our experienced team will guide you through the process, discuss your loved one's specific care needs,
                and create a tailored care plan.
                <br/>
                <br/>
                <span style={{fontWeight: 'bold'}}>
                  Take the first step today: </span>Contact us to schedule a free consultation and receive a
                personalized care assessment.
                <div style={{paddingTop: '32px'}}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="mui-buttons"
                    onClick={() => setOpenForm(true)}
                    disableElevation>
                    Request a Free Consultation </Button>
                </div>
              </div>
            </Typography>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}