import React from "react";


export const sectionOne = {
  topTitle: 'How We Help',
  topSubTitle: 'Our extensive expertise guarantees top-tier services at competitive prices for all our clients and ' +
    'candidates. Lyora Health Care Services specializes in healthcare, delivering both short-term and extended care ' +
    'directly to clients in their homes. Additionally, we provide live-in support and healthcare services at various ' +
    'establishments across the UK.',
  cardOneTitle: 'Home Care',
  cardOneDesc: [
 'At Lyora Healthcare Services, we are dedicated to providing services that are tailored to meet your unique needs, ' +
 'preferences, and ...'
  ],
  cardTwoTitle: 'CAMHS',
  cardTwoDesc: [
  'CAMHS stands for Child and Adolescent Mental Health Services, Lyora Healthcare Services offers a dedicated ' +
  'CAMHS program, catering specifically to the mental health needs of children and adolescents with ' +
  'personalized and compassionate care'
      ],
  cardThreeTitle: 'Domestic Care',
  cardThreeDesc: [
  'Putting your comfort first, Lyora Healthcare Services Ltd specializes in delivering customized domestic care services that perfectly ' +
  'align with your specific needs, preferences, and ...'
  ],
  cardFourTitle: 'Specialist Care',
  cardFourDesc: [
    'Our Care Teams undergo extensive training to effectively address a wide spectrum of specialised needs. ' +
    'We possess a deep understanding of the unique symptoms and ...'
  ]
}

export const detailSection = {
  topTitle: 'IT Support & Software Development',
  topSubTitle: 'We provide cost-effective IT support services, we have experience in implementing and supporting\n' +
    '          Microsoft-based IT infrastructures. We build and maintain software by leveraging cutting edge technology.\n' +
    '          In short, we provide an end-to-end support for all your software needs.',
  cardOneTitle: 'IT Support',
  cardOneDesc: [
    "Lyora healthcare services  Ltd provides Domiciliary care for our clients’ needs to be met within their communities.  " +
    "We also provide health and care staff to other agency like local authorities and care homes.\n" +
    "Domiciliary Care",
    "We provide service by supporting clients to maintain their personal hygiene and their daily living tasks within their own environment. " +
    "Lyorahealth Care Ltd will also be supporting adults at risk living in various schemes which include Supported Living, " +
    "Extra Care Housing and permanent placements.",
    "We offer premium care services through our experienced and skilled staffs who are dedicated in meeting the needs of the service users. " +
    "All these services are achieved by promoting a high standard of services delivery, " +
    "which embraces fundamental principles of Good Care practices and is witnessed and evaluated through the practice, " +
    "conduct and control of quality care in the domestic environment.",
    "Lyora Healthcare Services Ltd is registered with Care Quality Commission (CQC)."
  ],
  cardTwoTitle: 'Software Development',
  cardTwoDesc: [
    "We provide competent and qualified \"Agency Staff or locums\" to our clients by attracting, training, and retaining" +
    " qualified and experienced nurses and carers who embody the Lyora Healthcare culture of professional care delivery " +
    "and best outcomes. For the provision of temporary and permanent healthcare workers, we operate as a national and " +
    "international recruitment business and employment agency.",
    "In an industry where great service truly saves lives, regulatory legislation for the recruitment of healthcare " +
    "personnel is a crucial concern. The personnel at Lyora Healthcare Services, as well as our clients, " +
    "understand how important it is for us to follow these rules and regulations.",
    "The international Ethical Recruitment Charter is enshrined in the UK code of Practice for International " +
    "Recruitment of Health and Social Care Professionals in England, and Lyora Healthcare Services follows it. ",
    "Understanding our clients and employees in order to meet their unique needs has always been a key component of " +
    "our success at Lyora Healthcare Services. To achieve ongoing quality improvement in addressing your requirements," +
    " our management encourages input and stays current with reforms in the health and social care sectors."
  ]
}