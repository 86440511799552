export const headSection = {
  logo: 'here',
  topTitle: 'Your Health, Our Heart, At Home',
  topSubTitle: '- Ascot'
}

export const cardsData = [
  {'title': 'Domiciliary care', 'price': 250,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-25-12-23-11-12-21-exam-revision.jpg',
    'desc': 'We provide Domiciliary care for our clients’ needs to be met within ' +
        'their communities. We also provide ...', 'id': 2},
  {'title': 'Mental Health Services', 'price': 100,
  'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-25-12-23-11-18-00-exam-revision.jpg',
  'desc': 'We provide Children and Adolescent Mental Health Services (CAMHS) ... ', 'id': 1}
  ];

export const jobsData = [
  {'title': 'Full Time', 'price': 250,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-24-12-23-21-03-42-exam-revision.jpg',
    'desc': 'Full time roles ...', 'id': 2},
  {'title': 'Part Time', 'price': 100,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-24-12-23-21-03-42-exam-revision.jpg',
    'desc': 'Part time roles ...', 'id': 1},
  {'title': 'Temporary', 'price': 100,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-24-12-23-21-03-42-exam-revision.jpg',
    'desc': 'Temporary roles ...', 'id': 1}
];

export const howWeHelp = {
  howText: 'We are passionate about helping clients on how they can utilise their information technology (IT)' +
    ' to optimally achieve their business goals. Let our consultants soothe your IT headache.'
}